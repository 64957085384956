import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { loadStripe } from '@stripe/stripe-js';
import { Helmet } from 'react-helmet';

import { Grid } from '@core';
import { Loader } from '@components';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import {
  createPurchaseEventData,
  createPurchaseEventItem,
  firePurchaseEvent,
} from '@containers/checkout/utils/tracking';
import { PRICING_LINK } from '@shared/constants/constants';
import { PAYMENT_METHODS } from '@shared/constants/checkout';

/* This page is currently dedicated only to tracking and redirect logic after payment redirects
 * for stripe payments that can't accept success and failure urls
 * see confirmStripePayment function at payment-utils.js
 */
const PaymentPage = ({ location }) => {
  const {
    payment_intent_client_secret,
    payment_method,
    plan_id,
    plan_title,
    price,
    redirect_status,
    source_type,
    language,
  } = queryString.parse(location.search);

  useEffect(() => {
    if (
      payment_method === PAYMENT_METHODS.KLARNA ||
      payment_method === PAYMENT_METHODS.IDEAL ||
      payment_method === PAYMENT_METHODS.SOFORT
    ) {
      (async () => {
        try {
          const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY, {
            locale: 'en',
          });
          const result = await stripe.retrievePaymentIntent(payment_intent_client_secret);

          if (
            result.paymentIntent?.status === 'succeeded' ||
            (result.paymentIntent?.status === 'processing' &&
              payment_method === PAYMENT_METHODS.SOFORT)
          ) {
            firePurchaseEvent(
              createPurchaseEventData(
                result.paymentIntent.id,
                result.paymentIntent.currency === 'jpy'
                  ? result.paymentIntent.amount
                  : result.paymentIntent.amount / 100,
                result.paymentIntent.currency,
                payment_method,
                createPurchaseEventItem(plan_id, plan_title, 1, Number(price))
              )
            );
            trackCustomGA4Event({
              eventName: GA4_EVENTS.successfulPurchase,
              params: {
                currency: result.paymentIntent.currency,
                plan_id,
                payment_method,
              },
            });

            navigate(
              language !== 'en' ? `/${language.toLowerCase()}/payment/success` : '/payment/success'
            );
          } else {
            trackCustomGA4Event({
              eventName: GA4_EVENTS.purchaseFail,
              params: {
                payment_method,
              },
            });

            navigate(
              language !== 'en' ? `/${language.toLowerCase()}/payment/failure` : '/payment/failure'
            );
          }
        } catch (error) {
          // redirect to checkout page on error, e.g. if stripe hasn't loaded or if no payment intent secret (or wrong one) provided
          navigate(PRICING_LINK);
        }
      })();
    } else {
      // redirect to checkout page if navigated to this page accidentaly (not because of klarna redirect)
      navigate(PRICING_LINK);
    }
  }, [
    language,
    payment_intent_client_secret,
    payment_method,
    plan_id,
    plan_title,
    price,
    redirect_status,
    source_type,
  ]);

  return (
    <React.Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Grid.Container height="100vh" justifyContent="center">
        <Loader isLoading />
      </Grid.Container>
    </React.Fragment>
  );
};

export default PaymentPage;
